import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // ##############################################################################

    {path: '', pathMatch: 'full', redirectTo: 'sign-in'},
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.routing')
            }
        ]
    },
    {
        path: '',
        /*canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],*/
        component: LayoutComponent,
        data: {
            layout: 'compact'
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('app/modules/admin/dashboard/dashboard.routing')
            },
            {
                path: 'home',
                loadChildren: () => import('app/modules/landing/home/home.routing')
            },
            {
                path: 'admin',
                loadChildren: () => import('app/modules/admin/admin-routing')
            },
            {
                path: 'maintenance',
                loadChildren: () => import('app/modules/screen-dynamic-builder/maintenance/maintenance-routing')
            },
            {
                path: 'creditmain',
                loadChildren: () => import('app/modules/credit-admin/credit-admin-routing')
            },
            {
                path: 'officemanager',
                loadChildren: () => import('app/modules/office-manager/office-manager-routing')
            }
        ]
    }
];
