import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { InfoUserInterface } from 'app/core/interfaces/info-user.interface';
import { MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { SecureStorageService } from '../../../core/services/secure-storage.service';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [MatIconButton, MatMenuTrigger, MatMenu, MatMenuItem, MatIcon],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    infoUser: InfoUserInterface;
    userName: string = '';

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _router: Router,
        private secureStorage: SecureStorageService
    ) {}

    ngOnInit(): void {
        const info = JSON.parse(this.secureStorage.getItem('infoSession'));
        this.userName = info.userName;
        this.infoUser = info;
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.infoUser) {
            return;
        }
    }

    /*  Sign out */
    signOut(): void {
        this._router.navigate(['/sign-in']);
    }
}
