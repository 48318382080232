import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import {ApiCoreService} from '../../../../app/core/services/api-core.service';
import { FuseHorizontalNavigationBasicItemComponent } from './components/basic/basic.component';
import { FuseHorizontalNavigationBranchItemComponent } from './components/branch/branch.component';
import { FuseHorizontalNavigationSpacerItemComponent } from './components/spacer/spacer.component';
import { NgForOf, NgIf } from '@angular/common';

@Component({
    selector: 'fuse-horizontal-navigation',
    templateUrl: './horizontal.component.html',
    styleUrls: ['./horizontal.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'fuseHorizontalNavigation',
    imports: [
        FuseHorizontalNavigationBasicItemComponent,
        FuseHorizontalNavigationBranchItemComponent,
        FuseHorizontalNavigationSpacerItemComponent,
        NgForOf,
        NgIf,
    ],
    standalone: true,
})
export class FuseHorizontalNavigationComponent
    implements OnChanges, OnInit, OnDestroy
{
    @Input() name: string = this._fuseUtilsService.randomId();
    @Input() navigation: FuseNavigationItem[];

    onRefreshed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService,
        private apiService: ApiCoreService
    ) {
        this.apiService.getMenu().subscribe((response) => {
            response.data.forEach((item) => {
                let optMenu: FuseNavigationItem;
                if (item.submenu.length > 0) {
                    optMenu = {
                        id: item.title.toLowerCase(),
                        title: item.title,
                        type: 'aside',
                        icon: 'heroicons_outline:folder',
                        link: item.path,
                        children: [],
                    };
                    let subOpMenu: FuseNavigationItem;
                    item.submenu.forEach((item1) => {
                        if (item1.submenu.length > 0) {
                            subOpMenu = {
                                id: item.title.toLowerCase(),
                                title: item1.title,
                                type: 'collapsable',
                                icon: 'heroicons_outline:folder',
                                link: item1.path,
                                children: [],
                            };
                        } else {
                            subOpMenu = {
                                id: item.title.toLowerCase(),
                                title: item1.title,
                                type: 'basic',
                                icon: 'heroicons_outline:folder',
                                link:
                                    '/maintenance/dynamic-windows' + item1.path,
                            };
                        }
                        optMenu.children.push(subOpMenu);
                    });
                } else {
                    optMenu = {
                        id: item.title.toLowerCase(),
                        title: item.title,
                        type: 'basic',
                        icon: 'heroicons_outline:folder',
                        link: item.path,
                    };
                }
                this.navigation.push(optMenu);
            });
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Navigation
        if ('navigation' in changes) {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Make sure the name input is not an empty string
        if (this.name === '') {
            this.name = this._fuseUtilsService.randomId();
        }

        // Register the navigation component
        this._fuseNavigationService.registerComponent(this.name, this);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Deregister the navigation component from the registry
        this._fuseNavigationService.deregisterComponent(this.name);

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh the component to apply the changes
     */
    refresh(): void {
        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Execute the observable
        this.onRefreshed.next(true);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
