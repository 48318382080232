import { HttpErrorResponse, HttpEvent, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { SecureStorageService } from '../services/secure-storage.service';
import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const secureStorageService = inject(SecureStorageService);
    const router = inject(Router);

    const token = secureStorageService.getItem('token');
    const sessionId = secureStorageService.getItem('sessionId');

    // Siempre aseguramos que se pase una solicitud válida a `next()`
    let modifiedReq = req;

    if (token && sessionId) {
        modifiedReq = req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
                // @ts-ignore
                SessionId: sessionId,
            },
        });
    }

    return next(modifiedReq).pipe(
        tap({
            next: (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('HTTP Response:', event);
                }
            },
            error: (err: any) => {
                console.error('HTTP Error:', err);
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        console.warn('Unauthorized - Redirecting to sign-in');
                        router.navigate(['/sign-in']);
                    }
                }
            },
        })
    );
};
